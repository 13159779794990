import React from "react";
import { Route, useHistory, Switch, Redirect } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import PrivateRoute from './utils/privateRoute';
import PublicRoute from './utils/publicRoute';
import routes from './routes';
import NotFound from './components/NotFound'
const AppWithRouterAccess = () => {
  const history = useHistory();
  const onAuthRequired = () => {
    history.push("/login");
  };
  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_DOMAIN + "oauth2/default",
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + "/login/callback",
    onAuthRequired: onAuthRequired,
    pkce: true,
  });

  const restoreOriginalUri = (_, originalUri) => {
    window.location.replace(originalUri);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <PublicRoute exact path="/login" name="Login Page" />
      <Route exact path="/login/callback" name="Callback" component={LoginCallback}/>
      {routes.map((route, idx) => {
              return route.component ? (
                <PrivateRoute
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <route.component {...props} />
                  )} />
              ) : (null);
            })}
      {/* <Route path="*" component={NotFound} /> */}
    </Security>
  );
};

export default AppWithRouterAccess;
