import axios from "axios";
import { getToken } from './userSession';

const noAuthAxios = axios.create({
  baseURL:  process.env.REACT_APP_API_BASE_URL,
  timeout: 9000,
  headers: {'x-app-token': process.env.REACT_APP_API_TOKEN }
});

const mainAxios = axios.create({
  baseURL:  process.env.REACT_APP_API_BASE_URL,
  timeout: 9000,
  headers: {'x-app-token': process.env.REACT_APP_API_TOKEN,
            'x-access-token': getToken()
  }
});

mainAxios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    window.location = '/logout';
  }
  return error;
});

export {
  mainAxios,
  noAuthAxios
};