import React from 'react';
import './not_found.scss';

const NotFound = () =>
  <main className="common_main">
    <div className="common_inner_small">
      <div className="progress_wrap">
        <div className="progress_d_layout">
          <div className="not_found_container fm">
            <p className="not_found_large_text">404</p>
            <p className="not_found_medium_text">OOPS! NOTHING WAS FOUND</p>
          </div>
        </div>
      </div>
    </div>
  </main>

export default NotFound;