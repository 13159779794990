// return the user data from the session storage
export const getUserEmail = () => {
  const email = localStorage.getItem('email');
  if (email) return JSON.parse(email);
  else return null;
}

export const getUserName = () => {
  const name = localStorage.getItem('name');
  if (name) return JSON.parse(name);
  else return null;
}

export const checkExpire = () =>{
  let hours = process.env.REACT_APP_TOKEN_EXPIRE || 4;
  let expire = localStorage.getItem('expire')
  if (expire && (new Date().getTime() - expire > hours * 60 * 60 * 1000)) {
    localStorage.clear()
  }
}

// return the token from the session storage
export const getToken = () => {
  checkExpire();
  return localStorage.getItem('access_token') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.clear()
}

// set the token and user from the session storage
export const setUserSession = (access_token, email, name, remember_me = false) => {
  localStorage.setItem('access_token', access_token);
  localStorage.setItem('email', JSON.stringify(email));
  localStorage.setItem('name', JSON.stringify(name));
  if(!remember_me) localStorage.setItem('expire', new Date().getTime())
}