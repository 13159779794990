import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Header from '../../components/Header';
import { getToken } from '../../utils/userSession';
import {SyncSpinner} from '../../components/Spinner';
import NotFound from '../../components/NotFound'
// routes config
import routes from '../../routes';

function DefaultLayout(props) {
  return(
    <>
    { getToken()
    ? <>
        <Header/>
            {routes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <route.component {...props} />
                  )} />
              ) : (null);
            })}
            {/* <Route path="*" component={NotFound} /> */}

      </>
    : <SyncSpinner></SyncSpinner>
    }
    </>
  )
}

export default DefaultLayout;
