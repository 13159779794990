import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './userSession';
import { useOktaAuth } from "@okta/okta-react";
import DefaultLayout from '../layouts/DefaultLayout';
// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
  const { authState, oktaAuth } = useOktaAuth();
  const isLogged = () => {
    return getToken() || authState.isAuthenticated
  }

  return (
    <Route
      {...rest}
      render={(props) => isLogged() ? <DefaultLayout {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

export default PrivateRoute;